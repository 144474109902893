import React from 'react';
import { Row, Col, Space } from 'antd';
import { getChildrenToRender } from '../utils';

class Content extends React.PureComponent {
    render() {
        const { dataSource, isMobile, ...props } = this.props;
        const {
            wrapper,
            titleWrapper,
            page,
            childWrapper,
            explainWrapper,
        } = dataSource;
        return (
            <div {...props} {...wrapper}>
                {/* <span {...dataSource.img}>
                    <img src={dataSource.img.children} width="100%" alt="img" />
                </span> */}
                <div {...page}>
                    <div {...titleWrapper}>
                        {titleWrapper.children.map(getChildrenToRender)}
                    </div>
                    {explainWrapper && <div {...explainWrapper}>
                        {explainWrapper.children.map(getChildrenToRender)}
                    </div>}
                    <Row gutter={[40, 40]} >
                        <Col {...dataSource.imgWrapper} style={{ position: 'relative' }}>
                            <div {...dataSource.img} >
                                <img src={dataSource.img.children} alt="img" />
                            </div>
                        </Col>
                        <Col {...dataSource.textWrapper}>
                            <div {...childWrapper}>
                                {childWrapper.children.map(item => {
                                    const { img, title, content } = item.children
                                    return (
                                        <div {...item}>
                                            <Space size={12} align='start'>
                                                {img}
                                                <div>
                                                    <p style={{ fontSize: 24, lineHeight: '28px', fontWeight: 700, marginBottom: 8 }} >{title}</p>
                                                    <p style={{ fontSize: 16, lineHeight: '22px' }}>{content}</p>
                                                </div>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Content;
