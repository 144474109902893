import React from 'react';
import { Row, Col } from 'antd';
import { getChildrenToRender } from '../utils';

class Content extends React.PureComponent {
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const {
      wrapper,
      titleWrapper,
      page,
      childWrapper,
      explainWrapper,
    } = dataSource;
    return (
      <div {...props} {...wrapper} >
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          {explainWrapper && <div {...explainWrapper}>
            {explainWrapper.children.map(getChildrenToRender)}
          </div>}
          <Row gutter={[30, 30]}>
            {
              childWrapper.children.map((block, i) => {
                const { children: item, ...blockProps } = block;
                return (
                  <Col key={i.toString()} {...blockProps}>
                    <div className={item.className}>
                      <div className='content8-block-item-top'>{item.img}</div>
                      <div className='content8-block-item-bottom'>
                        <h3 className='content8-block-title'>{item.title}</h3>
                        <p className='content8-block-content'>{item.content}</p>
                      </div>
                    </div>
                  </Col>
                );
              })
            }
          </Row>
        </div>
      </div>
    );
  }
}

export default Content;
