import React from 'react';
import { Row, Col, Space, } from 'antd';
import { getChildrenToRender } from '../utils';

class Content extends React.PureComponent {
    render() {
        const { dataSource, isMobile, ...props } = this.props;
        const {
            wrapper,
            titleWrapper,
            page,
            childWrapper,
            explainWrapper,
            imgWrapper
        } = dataSource;
        return (
            <div {...props} {...wrapper}>
                <div {...page}>
                    <div {...titleWrapper}>
                        {titleWrapper.children.map(getChildrenToRender)}
                    </div>
                    {explainWrapper && <div {...explainWrapper}>
                        {explainWrapper.children.map(getChildrenToRender)}
                    </div>}
                    <Row gutter={[32, 32]} >
                        {childWrapper.children.map(item => {
                            const { img, title } = item.children
                            return (
                                <Col md={6} xs={24}>
                                    <div {...item}>
                                        <Space size={12} align='start'>
                                            {img}
                                            <div>
                                                <p style={{ fontSize: 24, lineHeight: '28px', fontWeight: 700, marginBottom: 8 }} >{title}</p>
                                            </div>
                                        </Space>
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                    <div {...imgWrapper}>
                        <img src={dataSource.img.children} width="100%" alt="img" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
