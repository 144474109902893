import React from 'react';
import { Row, Col, Space, Badge } from 'antd';
import { getChildrenToRender } from '../utils';

class Content extends React.PureComponent {
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const {
      wrapper,
      titleWrapper,
      page,
      childWrapper,
      explainWrapper,
    } = dataSource;
    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          {explainWrapper && <div {...explainWrapper}>
            {explainWrapper.children.map(getChildrenToRender)}
          </div>}
          <Row gutter={[32, 32]}>
            {childWrapper.children.map((block, i) => {
              const { children: item, ...blockProps } = block;
              return (
                <Col key={i.toString()} {...blockProps}>
                  <div className={item.className}>
                    <div className='content1-block-item-top'>{item.img}</div>
                    <div className='content1-block-item-bottom'>
                      <Space>
                        <h3 className='content1-block-title'>{item.title}</h3>
                      </Space>
                      <div className='content1-block-content'>
                        {item.content.map((text, index) => <Space key={index} align="start" style={{ marginLeft: 8 }}>
                          <Badge color="#000" />
                          {text}
                        </Space>)}
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default Content;
