import React, { Component } from "react"
import { enquireScreen } from "enquire-js"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Banner7 from "../components/Checkio/Banner7"
import Content8 from "../components/Checkio/Content8"
import Content1 from "../components/Checkio/Content1"
import Content2 from "../components/Checkio/Content2"
import Content3 from "../components/Checkio/Content3"
import Content4 from "../components/Checkio/Content4"
import Content5 from "../components/Checkio/Content5"

import {
  Banner07DataSource,
  Content01DataSource,
  Content08DataSource,
  Content02DataSource,
  Content03DataSource,
  Content04DataSource,
  Content05DataSource,
} from "../data/checkio.data"
// import Video from "../components/Checkio/Video"
import Customer from "../components/Checkio/Customer"
import { injectIntl } from "gatsby-plugin-intl"

let isMobile
enquireScreen(b => {
  isMobile = b
})

class CheckinPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
    }
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    const { intl } = this.props
    return (
      <>
        <SEO title={intl.formatMessage({ id: "seo.home" })} />
        <Layout>
          <Banner7
            id="Banner7_0"
            key="Banner7_0"
            dataSource={Banner07DataSource}
            isMobile={this.state.isMobile}
          />
          <Customer isMobile={this.state.isMobile} />
          <Content2
            id="Content0_2"
            key="Content0_2"
            dataSource={Content02DataSource}
            isMobile={this.state.isMobile}
          />
          <Content1
            id="Content0_1"
            key="Content0_1"
            dataSource={Content01DataSource}
            isMobile={this.state.isMobile}
          />
          <Content4
            id="Content0_4"
            key="Content0_4"
            dataSource={Content04DataSource}
            isMobile={this.state.isMobile}
          />
          <Content3
            id="Content0_3"
            key="Content0_3"
            dataSource={Content03DataSource}
            isMobile={this.state.isMobile}
          />
          <Content5
            id="Content0_5"
            key="Content0_5"
            dataSource={Content05DataSource}
            isMobile={this.state.isMobile}
          />
          {/* <Video /> */}
          <Content8
            id="Content0_8"
            key="Content0_8"
            dataSource={Content08DataSource}
            isMobile={this.state.isMobile}
          />
        </Layout>
      </>
    )
  }
}

export default injectIntl(CheckinPage)
