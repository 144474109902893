import React from 'react';
import { Row, Col, } from 'antd';
import { getChildrenToRender } from '../utils';

class Content extends React.PureComponent {
    render() {
        const { dataSource, isMobile, ...props } = this.props;
        const {
            wrapper,
            titleWrapper,
            page,
            childWrapper1,
            childWrapper2,
            explainWrapper,
        } = dataSource;
        return (
            <div {...props} {...wrapper}>
                <div {...page}>
                    <div {...titleWrapper}>
                        {titleWrapper.children.map(getChildrenToRender)}
                    </div>
                    {explainWrapper && <div {...explainWrapper}>
                        {explainWrapper.children.map(getChildrenToRender)}
                    </div>}
                    <Row gutter={[48, 48]} >
                        {childWrapper1.children.map(item => {
                            const { img, itemWrapper } = item.children
                            return (
                                <Col  {...itemWrapper} >
                                    <div >
                                        {img}
                                    </div>
                                </Col>

                            )
                        })}

                    </Row>
                    <Row gutter={[48, 48]} >
                        {childWrapper2.children.map(item => {
                            const { img, itemWrapper } = item.children
                            return (
                                <Col  {...itemWrapper}>
                                    <div>
                                        {img}
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
            </div>
        );
    }
}

export default Content;
