import React from 'react'
import { Col, Row, Space } from 'antd';
import { FormattedMessage } from "gatsby-plugin-intl"

export default function Customer({ isMobile }) {
  return (
    <div style={{ background: '#F5F6F9', textAlign: 'center' }} className='home-page-wrapper'>
      <div className='home-page' style={{ padding: 0 }}>
        <Row align='middle' justify='center'>
          <Col md={6}>
            <Space direction="vertical" size={20} style={{ padding: '20px 0' }}>
              <span style={{ fontSize: 16 }} ><FormattedMessage id='trusted_by' /></span>
              <img alt='img' src='/frasers.png' width={133} />
            </Space>
          </Col>
          <Col md={6}>
            <div>
              <img alt='img' src='/frasers01.png' style={{ width: '100%' }} />
            </div>
          </Col>
          <Col md={6}>
            <div>
              <img alt='img' src='/frasers02.png' style={{ width: '100%' }} />
            </div>
          </Col>
          <Col md={6}>
            <div>
              <img alt='img' src='/frasers03.png' style={{ width: '100%' }} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
